import { useEffect, useMemo } from 'react';

import { EnterpriseSearchResponse } from 'global/api/controller/EnterpriseSearchController';
import { SearchResultsResponse } from 'global/api/controller/SearchController';
import { useMountedState } from 'utils/hooks';

export const NO_ANSWER = "It seems like I don't have a good answer for this question. Please try another one";

export default function useEnterpriseSearchFallback(
  data: EnterpriseSearchResponse | null,
  searchResultsResponse: SearchResultsResponse | null,
  loading: boolean,
): EnterpriseSearchResponse | null {
  const [fallbackData, setFallbackData] = useMountedState<EnterpriseSearchResponse | null>(null);

  const regularSearchResults = useMemo(() => {
    if (searchResultsResponse === null) {
      return {
        hasResults: false,
        hasOneResult: false,
        hasMultipleResults: false,
      };
    }

    const {
      atoms, organizations, persons, topics,
    } = searchResultsResponse;

    const totalCountSum = [atoms, organizations, persons, topics]
      .reduce((sum, result) => sum + (result ? result.totalResultsCount : 0), 0);

    return {
      hasResults: totalCountSum !== 0,
      hasSingleResult: totalCountSum === 1,
      hasMultipleResults: totalCountSum > 1,
    };
  }, [searchResultsResponse]);

  useEffect(() => {
    if (searchResultsResponse === null || data === null || loading) {
      setFallbackData(null);
      return;
    }

    const { isSuccessfulAnswer } = data;

    if (isSuccessfulAnswer) {
      setFallbackData(data);
      return;
    }

    setFallbackData({
      ...data,
      answer: NO_ANSWER,
    });
  }, [searchResultsResponse, regularSearchResults, data, loading]);

  return fallbackData;
}
